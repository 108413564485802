<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row align-items-center mb-4">
      <div class="col-auto">
        <img src="@/assets/images/app/profile_icons.png" class="animate__animated animate__fadeInRight" height="45">
      </div>
      <div class="col">
        <h4 class="font-size-16 m-0 animate__animated animate__fadeInDown">{{name || accessPhone}}</h4>
        <div class="animate__animated animate__fadeInDown">{{$t('siteLang.Withdrawablebalance')}}: <strong class="text-primary">Rp. {{WithdrawableBalance}}</strong>  </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
          <!-- { name: 'support', params: { userId: 123 }} -->
        <ul class="list-group">
        <li class="list-group-item  mb-2 py-1 rounded" v-for="(value, index) in menu" :key="index">
          <router-link class="fw-medium text-body d-flex justify-content-between align-items-center custom-link" 
          :title="$t(value.title)" 
          :to="linkTo(value.routerName)">
          <span>{{$t(value.title)}}</span>  
          <span class="font-size-24"><i class="mdi mdi-chevron-right "></i></span>
         </router-link>    
        </li>
      </ul>
      </div>
    </div>
    <Lottie :title="''" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
//import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
/**
 * Starter page
 */
export default {
  components: { 
    Layout, 
    Lottie,
    Common
  },
  page() {
    return {
      title: this.$t('siteLang.MyProfile'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      showLottie:false,
      fullData:{},
      name:"",
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      title: '',
      items: [
        {
          text: appConfig.title,
          href: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      menu:[
        {
          title:"siteLang.MyProfile",
          routerName:"my profile",
        },
        {
          title:"siteLang.MyLoan",
          routerName:"Loan",
        },
        // {
        //   title:"My Repayment",
        //   routerName:"home",
        // },
        {
          title:"siteLang.MyWithdrawal",
          routerName:"Withdrawal",
        },
        {
          title:"siteLang.ChangeLanguage",
          routerName:"change language",
        },
        {
          title:"siteLang.Logout",
          routerName:"logout",
        },
      ],
      WithdrawableBalance:""
    };
  },
  mounted(){
    this.accessToken = this.$refs.commonFunc.getFullData().access_token
    this.accessPhone = this.$refs.commonFunc.getFullData().phone
    this.fullData = this.$refs.commonFunc.getFullData()
    this.name = this.fullData.name
    this.WithdrawableBalance = this.fullData.withdrawal_amount
  },
  methods:{
    linkTo(value) {
      const query = {};
      if (value === 'Withdrawal') {
        value = 'bill'
        query.p = 'withdrawal';
      }
      else if (value === 'Loan') {
        value = 'bill'
        query.p = 'loan';
      }
      return {
        name: value,
        query: query || ''
      };
    }
  },
  middleware: "authentication",
};
</script>